import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";

// This is where a single insight gets viewed

const InsightsTemplate = ({ data }) => {
	console.log("Read Insight data", data);
	const tags = data.post.tags.nodes;
	console.log(tags);
	return (
		<Layout>
			<div>
				<h1>{data.post.title}</h1>
				<div>
					<div>
						<h2>Tags:</h2>
						{tags.map((tag) => {
							return (
								<div key={tag.id}>
									<h2>{tag.name}</h2>
								</div>
							);
						})}
					</div>
				</div>
				<h3>Published data: {data.post.date}</h3>
				<div>
					<p dangerouslySetInnerHTML={{ __html: data.post.content }} />
				</div>
			</div>
		</Layout>
	);
};

export default InsightsTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		post: wpPost(id: { eq: $id }) {
			title
			content
			author {
				node {
					name
				}
			}
			date(formatString: "DD MM YYYY")
			categories {
				nodes {
					id
					name
					uri
					slug
				}
			}
			tags {
				nodes {
					name
					uri
				}
			}
		}
	}
`;
